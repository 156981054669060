export const getDeckAbbrv = (str) => {
  if (!str) {
    return 'NA'
  }

  let abbrv = str.substr(0, 2)
  const segments = str.split(' ')

  if (segments.length > 1) {
    abbrv = segments[0].charAt(0) + segments[1].charAt(0)
  }

  return abbrv.toUpperCase()
}

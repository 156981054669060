/** @typedef {import('./types').ButtonProps} ButtonProps */
/** @typedef {import('./types').AltButtonProps} AltButtonProps */
/** @typedef {import('./types').LinkButtonProps} LinkButtonProps */
/** @typedef {import('./types').AltLinkButtonProps} AltLinkButtonProps */
/** @typedef {import('./types').IconButton} IconButtonProps */
import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'

/**
 * @param {ButtonProps} props
 */
export const Button = ({ children, className, disabled, type, ...props }) => {
  return (
    <button
      className={cx(
        className,
        'py-11 px-24 mx-auto',
        'text-white hd-md',
        'bg-blue-bright antialiased',
        'border-2 border-blue-bright rounded-50',
        'active:border-white active:shadow-inset',
        {
          'hocus:shadow-button': !disabled,
          'opacity-50 cursor-default': disabled,
        }
      )}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {children}
    </button>
  )
}

/**
 * @param {LinkButtonProps} props
 */
export const LinkButton = ({ className, to, children }) => {
  return (
    <Link
      className={cx(
        className,
        'py-11 px-24 mx-auto',
        'text-white hd-md',
        'bg-blue-bright antialiased text-center no-underline',
        'border-2 border-blue-bright rounded-50',
        'active:border-white active:shadow-inset',
        {
          'inline-block': !className,
        }
      )}
      to={to}
    >
      {children}
    </Link>
  )
}

/**
 * @param {AltButtonProps} props
 */
export const AltButton = ({
  className,
  type,
  large,
  disabled,
  children,
  ...props
}) => {
  return (
    <button
      // @TODO disabled styles
      className={cx(
        className,
        'text-blue-bright text-center',
        'bg-white',
        'border-2 border-white rounded-34',
        'hocus:shadow-button',
        'active:border-white active:shadow-inset',
        {
          'inline-block': !className,
          'py-8 px-24 util-sm': !large,
          'py-11 px-24 hd-md': large,
        }
      )}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {children}
    </button>
  )
}

/**
 * @param {AltLinkButtonProps} props
 */
export const AltLinkButton = ({ className, large, to, children }) => {
  return (
    <Link
      className={cx(
        className,
        'text-blue-bright text-center no-underline',
        'bg-white',
        'border-2 border-white rounded-34',
        'hocus:shadow-button',
        'active:border-white active:shadow-inset',
        {
          'inline-block': !className,
          'py-8 px-24 util-sm': !large,
          'py-11 px-24 hd-md': large,
        }
      )}
      to={to}
    >
      {children}
    </Link>
  )
}

/**
 * @param {ButtonProps} props
 */
export const ClearButton = ({
  className,
  type,
  disabled,
  children,
  ...props
}) => {
  return (
    <button
      // @TODO disabled styles
      className={cx(
        className,
        'text-white text-center',
        'bg-transparent',
        'border-2 border-transparent rounded-34',
        'hocus:border-white hocus:shadow-none',
        'active:border-white active:shadow-inset',
        {
          'inline-block': !className,
          'py-8 px-24 util-sm': true,
        }
      )}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {children}
    </button>
  )
}

/**
 * @param {LinkButtonProps} props
 */
export const ClearLinkButton = ({ className, to, children }) => {
  return (
    <Link
      className={cx(
        className,
        'text-white text-center no-underline',
        'bg-transparent',
        'border-2 border-transparent rounded-34',
        'hocus:border-white hocus:shadow-none',
        'active:border-white active:shadow-inset',
        {
          'inline-block': !className,
          'py-8 px-24 util-sm': true,
        }
      )}
      to={to}
    >
      {children}
    </Link>
  )
}

/**
 * @param {IconButtonProps} props
 */
export const IconButton = ({
  className,
  type,
  large,
  disabled,
  text,
  children,
  ...props
}) => {
  return (
    <button
      // @TODO disabled styles
      className={cx(
        className,
        'antialiased relative',
        'text-blue-bright text-center',
        'bg-white',
        'border-2 border-white rounded-34',
        'hocus:shadow-button',
        'active:border-white active:shadow-inset',
        {
          'inline-block': !className,
          'py-8 px-24 util-sm': !large,
          'py-11 px-24 hd-md': large,
        }
      )}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {children}
      <span className="hd-sm absolute block text-white top-full whitespace-no-wrap text-center mt-8">
        {text}
      </span>
    </button>
  )
}

import React from 'react'
import cx from 'classnames'

import { AltLinkButton } from '../../components/button'

import {
  // DeckLibraryList,
  DeckLibrarySidebar,
  DeckLibraryActiveDeck,
  DeckLibraryListItem,
} from '../../components/deck-library-parts'

import PlusIcon from '../../images/svg/plus-icon.inline.svg'
import { useUserbaseDeckDBState } from '../../services/userbase/deckDBProvider'

const DecksPage = () => {
  const { decks, deck } = useUserbaseDeckDBState()

  const activeId = deck ? deck.itemId : null

  return (
    <section className="w-full max-w-page mx-auto">
      <h1 className="hd-xl text-white mb-24">Deck Library</h1>
      <div className="md:flex">
        <section
          className={cx('flex', 'md:w-3/4 md:flex-shrink-0', {
            'md-d:pb-32 md-d:mb-32 md-d:border-b-2 md-d:border-white': deck,
          })}
        >
          <div className="w-full xs:w-1/2" data-testid="other-decks">
            {decks.length > 0 &&
              decks.map((deckFromDb) => {
                return (
                  <DeckLibraryListItem
                    key={deckFromDb.itemId}
                    active={deckFromDb.itemId === activeId}
                    // @TODO model the deck better
                    deck={deckFromDb}
                  />
                )
              })}

            <footer className="pt-24">
              <AltLinkButton
                className="inline-flex items-center"
                to="/decks/new"
              >
                <PlusIcon className="mr-8" /> Add a Deck
              </AltLinkButton>
            </footer>
          </div>

          <DeckLibraryActiveDeck className="flex w-1/2 px-24 md:px-40 xs-d:hidden" />
        </section>
        <DeckLibrarySidebar />
      </div>
    </section>
  )
}

export default DecksPage

import React from 'react'
import { bool, shape, string, number } from 'prop-types'
import cx from 'classnames'

import { useUserbaseUser, useUserbaseDeckDB } from '../../services/userbase'
import { useUserbaseDeckDBState } from '../../services/userbase/deckDBProvider'

import { getDeckAbbrv } from '../../utils'

import {
  AltLinkButton,
  ClearButton,
  ClearLinkButton,
} from '../../components/button'

import ArrowRightIcon from '../../images/svg/arrow-right-icon.inline.svg'
import EditIcon from '../../images/svg/edit-icon.inline.svg'
import EyeIcon from '../../images/svg/eye-icon.inline.svg'
import TrashIcon from '../../images/svg/trash-icon.inline.svg'
import FolksyBgPuzzle from '../../images/svg/folksy-bg-puzzle.svg'

const DeckLibraryCard = ({ src, alt, second, third }) => {
  return (
    <div
      className={cx(
        'absolute w-full h-full bg-blue-organic shadow-card rounded overflow-hidden',
        {
          transform: second || third,
          'translate-y-14 opacity-75': second,
          'translate-y-28 opacity-50': third,
        }
      )}
    >
      <img
        className={cx('w-full h-full object-cover', {
          transform: second || third,
          'rotate-90': second,
          'rotate-180': third,
        })}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export const DeckLibraryListItem = ({ active, deck }) => {
  const { setUserDeck } = useUserbaseUser()

  return (
    <div className="border-b border-blue-soft py-4">
      <button
        disabled={active}
        onClick={() => {
          setUserDeck(deck.itemId)
        }}
        className={cx(
          'antialiased w-full flex items-center text-left rounded-8 p-12',
          {
            'bg-white-18': active,
          }
        )}
      >
        <span className="flex flex-shrink-0 items-center justify-center rect-deck-initials rounded-full bg-white hd-sm text-blue-bright mr-24">
          {getDeckAbbrv(deck.item.name)}
        </span>
        <div>
          <h3 className="hd-sm text-white">
            {deck.item.name || 'no name set'}
          </h3>

          <p className="text-sm leading-loose text-white">
            {deck.item.cardCount} cards
          </p>
        </div>
      </button>
    </div>
  )
}

export const DeckLibrarySidebar = () => {
  const { deck } = useUserbaseDeckDBState()
  const { deleteDeck } = useUserbaseDeckDB()

  if (!deck) {
    return null
  }

  return (
    <aside
      className={cx(
        'flex items-center',
        'md-d:justify-between md-d:space-x-4',
        'md:flex-col md:items-start md:space-y-12 md:w-3/4'
      )}
    >
      <AltLinkButton
        className="sm:min-w-200 flex items-center justify-center"
        large
        to="/decks/study"
      >
        <span className="sm-d:sr-only">Start</span>
        <ArrowRightIcon aria-hidden="true" className="sm:ml-10 flex-shrink-0" />
      </AltLinkButton>
      <ClearLinkButton className="flex items-center" to="/decks/edit">
        <EditIcon aria-hidden="true" className="sm:mr-8 flex-shrink-0" />
        <span className="sm-d:sr-only">Edit</span>
      </ClearLinkButton>
      <ClearLinkButton className="flex items-center" to="/decks/view">
        <EyeIcon
          aria-hidden="true"
          className="rect-eye-icon-sm sm:mr-8 flex-shrink-0"
        />
        <span className="sm-d:sr-only">View</span>
      </ClearLinkButton>
      <ClearButton
        className="flex items-center"
        onClick={() => {
          deleteDeck(deck.itemId)
        }}
      >
        <TrashIcon aria-hidden="true" className="sm:mr-8 flex-shrink-0" />
        <span className="sm-d:sr-only">Delete Deck</span>
      </ClearButton>
    </aside>
  )
}

export const DeckLibraryActiveDeck = ({ className }) => {
  const { deck } = useUserbaseDeckDBState()

  if (!deck) {
    return null
  }

  return (
    <article className={cx(className, 'antialiased')} data-testid="active-deck">
      <div className="w-full aspect-ratio-4/5 relative">
        <DeckLibraryCard third src={FolksyBgPuzzle} alt="" />
        <DeckLibraryCard second src={FolksyBgPuzzle} alt="" />
        <DeckLibraryCard src={FolksyBgPuzzle} alt="" />

        <div className="absolute top-1/2 transform -translate-y-1/2 left-0 w-full py-16 px-8 bg-blue-bright text-center">
          <h3 className="hd-md text-white">
            {deck.item.name || 'No Name Set'}
          </h3>

          <p className="text-sm text-white leading-loose">
            {deck.item.cardCount} cards
          </p>
        </div>
      </div>
    </article>
  )
}

DeckLibraryListItem.propTypes = {
  active: bool,
  activeLoading: bool,
  activeCount: number,
  deck: shape({
    itemId: string,
    item: shape({
      name: string,
    }),
  }).isRequired,
}

DeckLibraryCard.propTypes = {
  src: string,
  alt: string,
  second: bool,
  third: bool,
}

DeckLibraryActiveDeck.propTypes = {
  className: string,
}

import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import DecksPage from '../client-pages/decks'

import { AltLinkButton } from '../components/button'

import { useUserbaseUserState } from '../services/userbase/userProvider'
import { useUserbaseDeckDBState } from '../services/userbase/deckDBProvider'

import FolksyBubble from '../images/svg/folksy-bubble.inline.svg'
import PlusIcon from '../images/svg/plus-icon.inline.svg'

const IndexPage = () => {
  const { user } = useUserbaseUserState()
  const { deck } = useUserbaseDeckDBState()

  const HAS_DECK = user && deck
  const HAS_USER_NO_DECK = user && !deck

  return (
    <Layout>
      {HAS_DECK ? (
        <>
          <SEO title="Deck Library" />
          <DecksPage />
        </>
      ) : HAS_USER_NO_DECK ? (
        <>
          <SEO title="Deck Library" />

          <section className="w-full max-w-page mx-auto">
            <h1 className="hd-xl text-white mb-24">Deck Library</h1>
            <AltLinkButton to="/decks/new" className="inline-flex items-center">
              <PlusIcon className="mr-8" aria-hidden="true" /> Add a Deck
            </AltLinkButton>
          </section>
        </>
      ) : (
        <>
          <SEO title="Home" />
          <FolksyBubble className="block mx-auto rect-folksy-bubble-sm lg:rect-folksy-bubble-lg" />
        </>
      )}
    </Layout>
  )
}

export default IndexPage
